<template>
  <iframe src="https://www.hexiaoxiang.com/static5/music/webiste_music.html" class="music"></iframe>
</template>

<script>
export default {
  data(){
    return{

    }
  }
}
</script>

<style>
.music{
  width: 100%;
  height: 1300px;
  border: none
}

</style>